import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export const RichText = ({
  title = '',
  value,
  onChange,
  onBlur,
  onFocus,
  name,
  disabled = false,
  size = 12,
  required = true,
}) => {
  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData()
          loader.file.then((file) => {
            body.append('file', file)
            let headers = new Headers()
            headers.append('storage-api-key', process.env.REACT_APP_STORAGE_KEY)
            fetch(process.env.REACT_APP_STORAGE_URL + '/storage/single', {
              method: 'post',
              headers: headers,
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${process.env.REACT_APP_ASSETS_URL}/${res.data.id}`,
                })
              })
              .catch((err) => {
                reject(err)
              })
          })
        })
      },
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  return (
    <div className={`fv-row mb-7 col-${size}`}>
      <label className={`d-block fw-semibold fs-6 mb-5 ${required ? 'required' : ''}`}>
        {title}
      </label>
      <CKEditor
        name={name}
        editor={ClassicEditor}
        data={value}
        disabled={disabled}
        config={{
          extraPlugins: [uploadPlugin],
          image: {
            styles: {
              options: [
                {
                  name: 'side',
                  title: 'Side image',
                  className: 'aligncenter',
                  modelElements: ['imageBlock'],
                },
              ],
            },
          },
          mediaEmbed: {
            previewsInData: true,
          },
        }}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        // onReady={ editor => {
        //     // You can store the "editor" and use when it is needed.
        //     console.log( 'Editor is ready to use!', editor );
        // } }
        // onChange={ ( event, editor ) => {
        //     const data = editor.getData();
        //     console.log( { event, editor, data } );
        // } }
        // onBlur={ ( event, editor ) => {
        //     console.log( 'Blur.', editor );
        // } }
        // onFocus={ ( event, editor ) => {
        //     console.log( 'Focus.', editor );
        // } }
      />
    </div>
  )
}
