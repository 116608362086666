import moment from 'moment'
import {useEffect} from 'react'
import {useDashboardStore} from '../states/dashboard-wallet.state'
import DashboardCardComponent from './dashboard-card.component'

const TotalPointShareCardComponent = () => {
  const {totalPointShare, totalPointShareLoading, getTotalPointShare, startDate, endDate} =
    useDashboardStore()

  useEffect(() => {
    getTotalPointShare({start_date: undefined, end_date: undefined})
  }, [])

  useEffect(() => {
    if (startDate !== undefined && endDate !== undefined) {
      getTotalPointShare({
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
      })
    }
  }, [startDate, endDate])

  return (
    <>
      <DashboardCardComponent
        title='Total Point'
        color='danger'
        value={totalPointShare ?? 0}        
        icon={<i className={`	fab fa-bitcoin text-danger fs-1`} style={{marginRight: 10}}></i>}
        subtitle='Total point yang telah dibagikan'
        loading={totalPointShareLoading}
      />
    </>
  )
}

export default TotalPointShareCardComponent
