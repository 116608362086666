import {type} from 'os'
import {createContext, useEffect, useState} from 'react'
import {TotalDonationGroupV2} from '../../../crm/group-management/providers/group-management.provider'

export type TotalDonationGroupContextState = {
  totalDonation?: number
}

export const TotalDonationGroupContext = createContext<TotalDonationGroupContextState | null>(null)

interface TotalDonationContextProviderProps {
  groupId: string
  children: React.ReactNode
}

export const TotalDonationGroupContextProvider = ({
  groupId,
  children,
}: TotalDonationContextProviderProps) => {
  const [totalDonation, setTotalDonation] = useState<number>()

  const getTotal = async (groupId: string) => {
    if (groupId !== '') {
      const totalDonationGroup = await TotalDonationGroupV2(groupId)
      if (totalDonationGroup.status) {
        setTotalDonation(totalDonationGroup.data)
      }
    }
  }

  useEffect(() => {
    getTotal(groupId)
  }, [groupId])

  return (
    <TotalDonationGroupContext.Provider
      value={{
        totalDonation,
      }}
    >
      {children}
    </TotalDonationGroupContext.Provider>
  )
}
