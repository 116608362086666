import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {useAuth} from '../../../auth'
import {InputDatePicker} from '../../../forms/components/input.component'
import MemberScreen from '../../crm/group-management/screens/member.screen'
import DirectionScreen from '../../program/program-affiliate/screens/direction.screen'
import DonationAffiliateDashboardPage from '../../program/program-affiliate/screens/program-affiliate.screen'
// import TotalActiveProgramCardComponent from '../components/total-active-program-card.component'
import TotalUserCardComponent from '../components/total-wallet-user-card.component'
import TotalPointShareCardComponent from '../components/total-wallet-point-share-card.component'
import TotalWalletCardComponent from '../components/total-wallet-card.component'
import {useDashboardStore} from '../states/dashboard.state'
import DashboardAdminBranchScreen from './dashboard-admin-branch.screen'
import DashboardAdminSubBranchScreen from './dashboard-admin-subbranch.screen'
import DashboardMitraLembagaScreen from './dashboard-mitra-lembaga.screen'

const DashboardScreen = () => {
  const {startDate, endDate, setStartDate, setEndDate} = useDashboardStore()
  const {currentUser} = useAuth()

  return (
    <>
      <div className='row gy-5 g-xl-10'>
        <div className='col-12' style={{marginBottom: -20}}>
          <div className='row'>
            <div className='col-2'>
              <InputDatePicker
                id={'start_date'}
                title={'Start Date'}
                name={'start_date'}
                type={'date'}
                value={startDate}
                onChangeDate={(e) => {
                  setStartDate(e)
                }}
              />
            </div>
            <div className='col-2'>
              <InputDatePicker
                id={'end_date'}
                title={'End Date'}
                name={'end_date'}
                type={'date'}
                value={endDate}
                onChangeDate={(e) => {
                  setEndDate(e)
                }}
              />
            </div>
          </div>
        </div>
        <TotalWalletCardComponent/>
        <TotalPointShareCardComponent />
        <TotalUserCardComponent />
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      {currentUser?.data?.role?.slug ? (
        <>
          {currentUser?.data?.role?.slug === 'admin' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'superadmin' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-platform' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'user' ? <DashboardScreen /> : null}
          {currentUser?.data?.role?.slug === 'relawan' ? <DonationAffiliateDashboardPage /> : null}
          {currentUser?.data?.role?.slug === 'bendahara' ? <MemberScreen /> : null}
          {currentUser?.data?.role?.slug === 'korca' ? <DonationAffiliateDashboardPage /> : null}
          {currentUser?.data?.role?.slug === 'direksi' ? <DirectionScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-provinsi' ? (
            <DonationAffiliateDashboardPage />
          ) : null}
          {currentUser?.data?.role?.slug === 'admin-kota' ? <MemberScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-branch' ? <DashboardAdminBranchScreen /> : null}
          {currentUser?.data?.role?.slug === 'admin-subbranch' ? (
            <DashboardAdminSubBranchScreen />
          ) : null}
          {currentUser?.data?.role?.slug === 'mitra-lembaga' ? <DashboardMitraLembagaScreen /> : null}
        </>
      ) : null}
    </>
  )
}

export default DashboardWrapper
