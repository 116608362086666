import {type} from 'os'
import {createContext, useEffect, useState} from 'react'
import {DistrictData} from '../interfaces/district-result.interface'
import {ShowDistrict} from '../providers/district.provider'

export type DistrictContextState = {
  district?: DistrictData
}

export const DistrictContext = createContext<DistrictContextState | null>(null)

interface DistrictContextProviderProps {
  districtId: string
  children: React.ReactNode
}

export const DistrictContextProvider = ({districtId, children}: DistrictContextProviderProps) => {
  const [district, setDistrict] = useState<DistrictData>()

  const getDistrict = async (districtId: string) => {
    if (districtId !== '') {
      const response = await ShowDistrict(districtId)
      if (response.status) {
        setDistrict(response.data)
      }
    }
  }

  useEffect(() => {
    getDistrict(districtId)
  }, [districtId])

  return (
    <DistrictContext.Provider
      value={{
        district,
      }}
    >
      {children}
    </DistrictContext.Provider>
  )
}
