import {type} from 'os'
import {createContext, useEffect, useState} from 'react'
import {GroupData} from '../../group-management/interfaces/group-management-result.interface'
import {ShowGroup} from '../../group-management/providers/group-management.provider'
export type GroupContextState = {
  group?: GroupData
}

export const GroupContext = createContext<GroupContextState | null>(null)

interface GroupContextProviderProps {
  groupId: string
  children: React.ReactNode
}

export const GroupContextProvider = ({groupId, children}: GroupContextProviderProps) => {
  const [group, setGroup] = useState<GroupData>()

  const getGroup = async (groupId: string) => {
    if (groupId !== '') {
      const response = await ShowGroup(groupId)
      if (response.status) {
        setGroup(response.data)
      }
    }
  }

  useEffect(() => {
    getGroup(groupId)
  }, [groupId])

  return (
    <GroupContext.Provider
      value={{
        group,
      }}
    >
      {children}
    </GroupContext.Provider>
  )
}
