import {async} from 'q'
import {toast} from 'react-toastify'
import create from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  SelectData,
  FormProps,
} from '../../../../../interfaces/form-action-interfaces'
import {GroupUser} from '../../group-management/interfaces/group-management-result.interface'
import {KorcaResultInterface, KorcaData, GroupWork} from '../interfaces/korca-result.interface'
import {KorcaSingleResultInterface} from '../interfaces/korca-single-result.interface'
import {
  GetAllKorcaParam,
  GetAllKorca,
  DeleteKorca,
  UpdateKorca,
  SubmitKorca,
  ShowKorcaDetail,
} from '../providers/korca-management.provider'

interface KorcaState {
  korcaGroupLoading: boolean
  korcaLoading: boolean
  korcaItems: KorcaData[]
  korcaModel?: KorcaResultInterface
  getKorca: (param: GetAllKorcaParam) => void
  onDelete: (id: string, params: GetAllKorcaParam) => void
  getKorcaGroup: (id: string) => void
  korcaGroupItems?: KorcaData
  korcaGroupModel?: KorcaSingleResultInterface
}

export const useKorcaStore = create<KorcaState>((set, get) => ({
  korcaGroupLoading: false,
  korcaLoading: false,
  korcaItems: [],
  korcaModel: undefined,
  korcaGroupItems: undefined,
  getKorca: async (params: GetAllKorcaParam) => {
    set({korcaLoading: true})
    const response = await GetAllKorca(params)
    set({korcaModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        set({korcaItems: response.data.items})
      } else {
        set({korcaItems: []})
      }
    } else {
      set({korcaItems: []})
    }
    set({korcaLoading: false})
  },
  onDelete: async (id: string, params: GetAllKorcaParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({korcaLoading: true})
      const response = await DeleteKorca(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getKorca(params)
      set({korcaLoading: false})
    }
  },
  korcaGroupModel: undefined,
  getKorcaGroup: async (id: string) => {
    set({korcaGroupLoading: true})
    const response = await ShowKorcaDetail(id)
    set({korcaGroupModel: response})
    if (response.status) {
      set({korcaGroupItems: response.data})
    } else {
      set({korcaGroupItems: undefined})
    }
    set({korcaGroupLoading: false})
  },
}))

interface KorcaFormState {
  field?: KorcaData
  setField?: (item: KorcaData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  onSubmit?: () => void
  formLoading?: boolean
  submitDone?: boolean
  korcaData?: KorcaData
  fieldItems: GroupUser[]
}

const formInitialState = {
  loading: false,
  form: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
  korcaData: undefined,
}

export const useKorcaFormStore = create<KorcaFormState>((set, get) => ({
  field: undefined,
  fieldItems: [],
  serviceData: undefined,
  setField: (item: KorcaData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: async (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []

    forms = [
      {
        id: 'district_lead',
        title: 'Nama Korca',
        placeholder: 'Nama Korca...',
        type: 'select-with-text',
        name: 'district_lead',
        value: {
          label: get().field?.district_lead,
          value: get().field?.district_lead,
        },
        selectData: [],

        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'province_id',
        title: 'Provinsi',
        placeholder: 'Provinsi...',
        type: 'select-with-text',
        name: 'province_id',
        value: {
          label: get().field?.province?.name,
          value: get().field?.province?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'city_id',
        title: 'Kota/Kabupaten',
        placeholder: 'Kota/Kabupaten...',
        type: 'select-with-text',
        name: 'city_id',
        value: {
          label: get()?.field?.city?.name,
          value: get()?.field?.city?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'district_id',
        title: 'Kecamatan',
        placeholder: 'Kecamatan...',
        type: 'select-with-text',
        name: 'district_id',
        value: {
          label: get().field?.district?.name,
          value: get().field?.district?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'subdistrict_id',
        title: 'Kelurahan',
        placeholder: 'Kelurahan...',
        type: 'select-with-text',
        name: 'subdistrict_id',
        value: {
          label: get().field?.subdistrict?.name,
          value: get().field?.subdistrict?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]
    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }
    form = {...form, group_work: get()?.field?.group_work ?? []}
    let res: KorcaSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitKorca(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateKorca(get().formParam?.id, form)

    if (res.status) {
      toast.success(res.message)
      set({korcaData: res.data, submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({korcaData: res.data, submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
