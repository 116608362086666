import { useEffect } from "react";
import { useDashboardStore } from "../states/dashboard.state";
import DashboardCardComponent from "./dashboard-card.component";

const TotalActiveProgramCardComponent = () => {

  const {
    totalActiveProgram, 
    totalActiveProgramLoading,
    getTotalActiveProgram
  } = useDashboardStore();

  useEffect(() => {
    getTotalActiveProgram();
  }, [])

  return (
    <>
      <DashboardCardComponent
        title="Total Active Program"
        color="success"
        value={totalActiveProgram ?? 0}
        icon={<i className={`fas fa-gift text-success fs-2`} style={{ marginRight: 10 }}></i>}
        subtitle="Jumlah program aktif"
        loading={totalActiveProgramLoading}
      />
    </>
  );
}

export default TotalActiveProgramCardComponent;