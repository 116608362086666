import React, {useEffect, useState} from 'react'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {useAuth} from '../../../../auth'
import {useDashboardAffiliateStore} from '../states/program-affiliate.state'
import {useAffiliatorCompanyStore} from '../../../auth/affiliator-management/states/affiliator-state'

ChartJS.register(ArcElement, Tooltip, Legend)

export const TotalDonationByAffiliate = () => {
  const {
    getTotalDonationAffiliate,

    getTotalCustomer,
    getTotalDonationAffiliateByTransfer,
    totalDonationAffiliateByTransfer,

    totalDonationAffiliateByTunai,
    totalDonationAffiliateByTunaiLoading,
    getTotalDonationAffiliateByTunai,

    getTotalDonationByKorca,
    totalDonationByKorca,

    getTotalDonationByKorcaTunai,
    totalDonationByKorcaTunai,

    getTotalDonationByKorcaTransfer,
    totalDonationByKorcaTransfer,
  } = useDashboardAffiliateStore()

  const {totalDonationMember, getTotalDoantionMember, totalDonationMemberLoading} =
    useAffiliatorCompanyStore()

  const {currentUser} = useAuth()

  useEffect(() => {
    getTotalDoantionMember(currentUser?.data?.id)
  }, [])

  useEffect(() => {
    getTotalDonationAffiliate()
  }, [])

  useEffect(() => {
    getTotalCustomer()
  }, [])

  useEffect(() => {
    getTotalDonationAffiliateByTransfer(currentUser?.data?.id)
  }, [])

  useEffect(() => {
    getTotalDonationAffiliateByTunai(currentUser?.data?.id)
  }, [])
  useEffect(() => {
    getTotalDonationByKorca()
    getTotalDonationByKorcaTunai()
    getTotalDonationByKorcaTransfer()
  }, [])
  const data = {
    labels: ['Transfer', 'Tunai', 'Affiliate'],
    datasets: [
      {
        label: 'c',
        data: [
          totalDonationByKorcaTunai,
          totalDonationByKorcaTransfer,
          totalDonationByKorca - totalDonationByKorcaTunai - totalDonationAffiliateByTransfer,
        ],
        backgroundColor: ['rgba(31, 117, 254)', 'rgba(255, 115, 0)', 'rgba(124, 221, 221)'],
        borderWidth: 1,
      },
    ],
  }
  const dataKorca = {
    labels: ['Transfer', 'Tunai', 'Affiliate'],
    datasets: [
      {
        label: 'c',
        data: [
          totalDonationAffiliateByTransfer,
          totalDonationAffiliateByTunai,
          totalDonationMember,
        ],
        backgroundColor: ['rgba(31, 117, 254)', 'rgba(255, 115, 0)', 'rgba(124, 221, 221)'],
        borderWidth: 1,
      },
    ],
  }

  return (
    <>
      <div className='col-xxl-6'>
        <div className='card'>
          <div className=' '>
            <div className='card-header border-0 '>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-5 '>Donation Type</span>
                <span className='text-muted fw-semibold fs-7'>
                  Jumlah Donasi Setiap Jenis Transaksi
                </span>
              </h3>
            </div>

            <div className='card py-5 bg-white align-items-center    '>
              <div className='w-50 h-50'>
                <Pie data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export function DonationPieChart() {
  return <TotalDonationByAffiliate />
}

export default DonationPieChart
