import {AxiosError} from 'axios'
import {ApiCrm, ApiProgram} from '../../../../../api/api'
import {DonationAffiliateSingleResultInterface} from '../interfaces/program-affiliate-single-resultInterface'
import {
  DonationAffiliateResultInterface,
  DonationAffiliateData,
} from '../interfaces/program-affiliate-resultInterface'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {TotalDonationByBranchInterface} from '../interfaces/total-donation-by-branch.interface'
import {TotalDonationByMitraLembagaInterface} from '../interfaces/total-donation-by-mitra-lembaga.interface'

export interface GetAllDonationAffiliateParam {
  page?: number
  size?: number
  reference?: string
  program_id?: string
}

export interface GetDashboardAdminParam {
  start_date?: string
  end_date?: string
  province_code?: string
  city_code?: string
  community_code?: string
}

export const GetAllDonationAffiliate = async (
  params: GetAllDonationAffiliateParam
): Promise<DonationAffiliateResultInterface> => {
  try {
    let response = await ApiProgram.get('donations-by-affiliate-code', {params: params})
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: DonationAffiliateResultInterface = a.response?.data
    return response
  }
}

export const ShowDonationAffiliate = async (
  id: string
): Promise<DonationAffiliateSingleResultInterface> => {
  try {
    let response = await ApiProgram.get('/public/donations/' + id)
    return response.data as DonationAffiliateSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationAffiliateSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitDonationAffiliate = async (
  form: DonationAffiliateData
): Promise<DonationAffiliateSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/donation', form)
    return response.data as DonationAffiliateSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationAffiliateSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateDonationAffiliate = async (
  id: string,
  form: DonationAffiliateData
): Promise<DonationAffiliateSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/donation/' + id, form)
    return response.data as DonationAffiliateSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: DonationAffiliateSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteDonationAffiliate = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.delete('/donation/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationAffiliate = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('total-donations-affiliate')
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationAffiliateByTunai = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-affiliator-tunai/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}
export const GetTotalDonationAffiliateByTransfer = async (
  id: string
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-donation-affiliator-transfer/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalCustomer = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.get('total-customers-affiliate')
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationByBranch = async (): Promise<TotalDonationByBranchInterface> => {
  try {
    let response = await ApiProgram.get(
      'total-donations-branch?start_date=2023-01-01&end_date=2023-12-31'
    )
    return response.data as TotalDonationByBranchInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByBranchInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationBySubbranch = async (
  params: GetDashboardAdminParam
): Promise<TotalDonationByBranchInterface> => {
  try {
    let response = await ApiProgram.get('total-donations-subbranch', {
      params: params,
    })
    return response.data as TotalDonationByBranchInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByBranchInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationByMitraLembaga = async (): Promise<TotalDonationByMitraLembagaInterface> => {
  try {
    let response = await ApiProgram.get(
      'partner-total-donation'
    )
    return response.data as TotalDonationByMitraLembagaInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByMitraLembagaInterface = a.response?.data
    return response
  }
}

export const GetTotalDonationSuccessByMitraLembaga = async (): Promise<TotalDonationByMitraLembagaInterface> => {
  try {
    let response = await ApiProgram.get(
      'partner-total-transaction'
    )
    return response.data as TotalDonationByMitraLembagaInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByMitraLembagaInterface = a.response?.data
    return response
  }
}

export const GetTotalCustomerByMitraLembaga = async (): Promise<TotalDonationByMitraLembagaInterface> => {
  try {
    let response = await ApiProgram.get(
      'partner-total-customer'
    )
    return response.data as TotalDonationByMitraLembagaInterface
  } catch (e) {
    let a = e as AxiosError
    let response: TotalDonationByMitraLembagaInterface = a.response?.data
    return response
  }
}


