import {useCallback, useEffect, useMemo} from 'react'
import ReactFlow, {
  Node,
  Controls,
  Background,
  addEdge,
  useEdgesState,
  useNodesState,
  Edge,
  Connection,
} from 'reactflow'

// 👇 you need to import the reactflow styles
import 'reactflow/dist/style.css'
import {KTCard, KTSVG} from '../../../../../../../_metronic/helpers'
import {
  useFlowBuilderFormState,
  useFlowBuilderStore,
  useReactFlowStore,
} from '../../states/flow-builder.state'
import {v4 as uuidv4} from 'uuid'
import {useWhatsappChannelStore} from '../../../whatsapp-channel-management/states/whatsapp-channel.state'
import {useAuth} from '../../../../../auth'
import {useLocation, useNavigate} from 'react-router-dom'
import {FormProps} from '../../../../../../interfaces/form-action-interfaces'
import FormsComponent from '../../../../../forms/components/forms.component'
import FlowBuilderFormDetailModalComponent from '../../components/flow-builder-form-detail-modal.component'
import {FlowSingleResultInterfaceData} from '../../interfaces/flow-single-result.interface'
import CardNodeComponentV2, {CardNodeData} from '../../components/card-node-v2.component'
import CardNodeComponent from '../../components/card-node.component'

// const initialEdges: Edge[] = [{ id: 'e1-2', source: '1', target: '2', animated: true  }];

const FlowBuilderMarketing = () => {
  const nodeTypes = useMemo(() => ({cardNodeComponent: CardNodeComponentV2}), [])
  const {
    nodes,
    edges,
    onEdgesChange,
    onNodesChange,
    onConnect,
    addNodes,
    generateNodes,
    generateEdges,
    resetReactFlow,
  } = useReactFlowStore()
  const {
    reset,
    field,
    setFormSelectData,
    generateForms,
    forms,
    setForms,
    onSubmit,
    submitDone,
    formLoading,
  } = useFlowBuilderFormState()
  const {currentUser} = useAuth()
  const {selectedNode} = useFlowBuilderStore()
  const location = useLocation()
  const navigate = useNavigate()
  const {getWhatsapps, whatsappSelectDatas} = useWhatsappChannelStore()
  const {getFlows} = useFlowBuilderStore()

  useEffect(() => {
    if (location !== undefined) {
      const formProps = location.state as FormProps
      generateForms(formProps)
    }
  }, [location])

  useEffect(() => {
    generateNodes(field)
    generateEdges(field)
  }, [field])

  useEffect(() => {
    if (currentUser !== undefined) {
      getWhatsapps({
        page: 0,
        size: 10,
        company_channel_id: currentUser?.data?.company_channel?.id,
      })
    }
  }, [currentUser])

  useEffect(() => {
    return () => {
      resetReactFlow()
    }
  }, [])

  useEffect(() => {
    if (whatsappSelectDatas.length > 0 && forms.length > 0) {
      setFormSelectData(2, whatsappSelectDatas)
    }
  }, [whatsappSelectDatas])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getFlows({
        page: 0,
        size: 10,
        company_id: currentUser?.data?.company?.id,
      })
      reset()
      navigate(-1)
    }
  }, [submitDone, formLoading])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Flow Builder</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Flow Builder</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='w-100'>
            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <FormsComponent
                forms={forms}
                setForms={setForms}
                uploadFileCb={(e) => console.log(e)}
              />
            </div>
            <label className={`d-block fw-semibold fs-6 mb-5`}>Flow</label>
            <div
              className='d-flex flex-row overflow-auto'
              style={{
                marginBottom: 10,
              }}
            >
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  addNodes()
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Create New Flow
              </button>
            </div>
            <div className='d-flex overflow-auto h-550px'>
              <div
                style={{
                  flexGrow: 1,
                  fontSize: 12,
                }}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onEdgesChange={onEdgesChange}
                  onNodesChange={onNodesChange}
                  onConnect={onConnect}
                  proOptions={{
                    hideAttribution: true,
                    account: process.env.REACT_APP_REACTFLOW_ACCOUNT,
                  }}
                >
                  {/* <MiniMap /> */}
                  <Controls />
                  <Background
                    color='#000'
                    style={{
                      border: '1px solid #E4E6EF',
                      borderRadius: '5px',
                    }}
                  />
                </ReactFlow>
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              onSubmit(nodes, edges, currentUser?.data?.company?.id)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Submit
          </button>
        </div>
      </KTCard>
      {selectedNode !== undefined && <FlowBuilderFormDetailModalComponent />}
    </>
  )
}

const FlowBuilderScreenOld = () => {
  const nodeTypes = useMemo(() => ({cardNodeComponent: CardNodeComponent}), [])
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const onConnect = useCallback(
    (params: Edge | Connection) => {
      setEdges((eds) => addEdge({...params, animated: true}, eds))
    },
    [setEdges]
  )
  const {
    flowNodes,
    flowEdges,
    setFlowNodes,
    generateFlowNodes,
    generateFlowEdges,
    setFlowNodesV2,
    selectedNode,
  } = useFlowBuilderStore()
  const {getWhatsapps, whatsappSelectDatas} = useWhatsappChannelStore()
  const {currentUser} = useAuth()
  const {
    reset,
    field,
    setFormSelectData,
    generateForms,
    forms,
    setForms,
    onSubmit,
    submitDone,
    formLoading,
  } = useFlowBuilderFormState()
  const location = useLocation()
  const navigate = useNavigate()
  const {getFlows} = useFlowBuilderStore()

  useEffect(() => {
    console.log('field', field)
    // generateFlowNodes(field);
    // generateFlowEdges(field);
    generateFlowNodesV2(field)
    generateFlowEdgesV2(field)
  }, [field])

  const generateFlowNodesV2 = (flow: FlowSingleResultInterfaceData) => {
    console.log('flow', flow)
    if (flow === undefined) {
      const node: Node<CardNodeData> = {
        id: uuidv4(),
        position: {x: 0, y: 0},
        data: {
          title: 'Flow #1',
          message: '',
          buttons: [],
          lists: [],
          text_based_actions: [],
          text_based_action_numbers: [],
          type: 'text',
          media: '',
          save_as: '',
        },
        type: 'cardNodeComponent',
      }
      const tempNodes: Node<CardNodeData>[] = []
      tempNodes.push(node)
      setNodes(tempNodes)
      setFlowNodesV2(tempNodes)
    } else {
      const tempNodes: Node<CardNodeData>[] = []
      for (const item of flow?.flow_builder?.flow_builder_nodes) {
        tempNodes.push({
          id: item.id,
          position: {x: item.position.x, y: item.position.y},
          data: {
            title: item.data.title,
            message: item.data.message,
            buttons: item.data.buttons,
            type: item.data.type,
            media: item.data.media,
            lists: item.data.lists,
            text_based_actions: item.data.text_based_actions,
            text_based_action_numbers: item.data.text_based_action_numbers,
            save_as: item.data.save_as,
          },
          type: item.type,
          width: item.width,
          height: item.height,
          selected: item.selected,
          positionAbsolute: {
            x: item.positionAbsolute.x,
            y: item.positionAbsolute.y,
          },
          dragging: item.dragging,
        })
      }
      setNodes(tempNodes)
      setFlowNodesV2(tempNodes)
    }
  }

  const generateFlowEdgesV2 = (flow?: FlowSingleResultInterfaceData) => {
    if (flow !== undefined) {
      const tempEdges: Edge<any>[] = []
      for (const item of flow?.flow_builder?.flow_builder_edges) {
        tempEdges.push({
          source: item.source,
          sourceHandle: item.sourceHandle,
          target: item.target,
          targetHandle: item.targetHandle,
          id: item.id,
        })
      }
      setEdges(tempEdges)
    }
  }

  useEffect(() => {
    if (location !== undefined) {
      const formProps = location.state as FormProps
      generateForms(formProps)
    }
  }, [location])

  useEffect(() => {
    if (currentUser !== undefined) {
      getWhatsapps({
        page: 0,
        size: 10,
        company_channel_id: currentUser?.data?.company_channel?.id,
      })
    }
  }, [currentUser])

  useEffect(() => {
    console.log('flowNodes', flowNodes)
    if (flowNodes.length > 0) {
      setNodes(flowNodes)
    }
  }, [flowNodes])

  // useEffect(() => {
  //   console.log("flowEdges", flowEdges)
  //   if (flowEdges.length > 0) {
  //     setEdges(flowEdges);
  //   }
  // }, [flowEdges])

  // useEffect(() => {
  //   console.log(nodes)
  // }, [nodes])

  // useEffect(() => {
  //   console.log("asdasd", edges);
  // }, [edges])

  useEffect(() => {
    if (whatsappSelectDatas.length > 0 && forms.length > 0) {
      setFormSelectData(2, whatsappSelectDatas)
    }
  }, [whatsappSelectDatas])

  useEffect(() => {
    if (submitDone === true && formLoading === false) {
      getFlows({
        page: 0,
        size: 10,
        company_id: currentUser?.data?.company?.id,
      })
      reset()
      navigate(-1)
    }
  }, [submitDone, formLoading])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Flow Builder</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Flow Builder</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='w-100'>
            <div className='mb-10 fv-row fv-plugins-icon-container'>
              <FormsComponent
                forms={forms}
                setForms={setForms}
                uploadFileCb={(e) => console.log(e)}
              />
            </div>
            <label className={`d-block fw-semibold fs-6 mb-5`}>Flow</label>
            <div
              className='d-flex flex-row overflow-auto'
              style={{
                marginBottom: 10,
              }}
            >
              <button
                type='button'
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  const test = [...nodes]
                  const node: Node<CardNodeData> = {
                    id: uuidv4(),
                    position: {x: 0, y: 200},
                    data: {
                      title: `Flow #${test.length + 1}`,
                      message: '',
                      buttons: [],
                      type: 'text',
                      media: '',
                      lists: [],
                      save_as: '',
                    },
                    type: 'cardNodeComponent',
                  }
                  test.push(node)
                  setFlowNodes(node)
                  setNodes(test)
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                Create New Flow
              </button>
            </div>
            <div className='d-flex overflow-auto h-550px'>
              <div
                style={{
                  flexGrow: 1,
                  fontSize: 12,
                }}
              >
                <ReactFlow
                  nodes={nodes}
                  edges={edges}
                  nodeTypes={nodeTypes}
                  onEdgesChange={onEdgesChange}
                  onNodesChange={onNodesChange}
                  onNodeDragStop={(e) => {
                    console.log('asdasd', e)
                  }}
                  onConnect={onConnect}
                  proOptions={{
                    hideAttribution: true,
                    account: process.env.REACT_APP_REACTFLOW_ACCOUNT,
                  }}
                >
                  {/* <MiniMap /> */}
                  <Controls />
                  <Background
                    color='#000'
                    style={{
                      border: '1px solid #E4E6EF',
                      borderRadius: '5px',
                    }}
                  />
                </ReactFlow>
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              const test = [...nodes]
              const node: Node<CardNodeData> = {
                id: uuidv4(),
                position: {x: 0, y: 200},
                data: {
                  title: `Flow #${test.length + 1}`,
                  message: '',
                  buttons: [],
                  type: 'text',
                  media: '',
                  lists: [],
                  save_as: '',
                },
                type: 'cardNodeComponent',
              }
              test.push(node)
              onSubmit(nodes, edges, currentUser?.data?.company?.id)
            }}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Submit
          </button>
        </div>
      </KTCard>
      {selectedNode !== undefined && <FlowBuilderFormDetailModalComponent />}
    </>
  )
}

export default FlowBuilderMarketing
