/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import SidebarMenuMainRelawan from './SidebarMenuMainRelawan'
import SidebarMenuMainKorca from './SidebarMenuMainKorca'
import SidebarMenuMainBendahara from './SidebarMenuMainBendahara'
import SidebarMenuMainAdmin from './SidebarMenuMainAdmin'
import SidebarMenuMainDireksi from './SidebarMenuMainDireksi'
import SidebarMenuMainAdminPlatform from './SideBarMenuMainAdminPlatform'
import SidebarMenuMainAdminBranch from './SidebarMenuMainAdminBranch'
import SidebarMenuMainAdminSubBranch from './SidebarMenuMainAdminSubbranch'
import SidebarMenuMainMitraLembaga from './SidebarMenuMainMitraLembaga'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      {currentUser?.data?.role?.slug === 'relawan' ? <SidebarMenuMainRelawan /> : null}
      {currentUser?.data?.role?.slug === 'korca' ? <SidebarMenuMainKorca /> : null}
      {currentUser?.data?.role?.slug === 'direksi' ? <SidebarMenuMainDireksi /> : null}
      {currentUser?.data?.role?.slug === 'bendahara' ? <SidebarMenuMainBendahara /> : null}
      {currentUser?.data?.role?.slug === 'superadmin' ? <SidebarMenuMainAdmin /> : null}
      {currentUser?.data?.role?.slug === 'admin-platform' ? <SidebarMenuMainAdminPlatform /> : null}
      {currentUser?.data?.role?.slug === 'admin' ? <SidebarMenuMainAdmin /> : null}
      {currentUser?.data?.role?.slug === 'admin-branch' ? <SidebarMenuMainAdminBranch /> : null}
      {currentUser?.data?.role?.slug === 'admin-subbranch' ? (
        <SidebarMenuMainAdminSubBranch />
      ) : null}
      {currentUser?.data?.role?.slug === 'mitra-lembaga' ? <SidebarMenuMainMitraLembaga /> : null }
    </>
  )
}

export {SidebarMenuMain}
