import { AxiosError } from "axios";
import { ApiCrm } from "../../../../../api/api";
import { GeneralInterface } from "../../../../../interfaces/general.interface";
import { MessageTemplateResultInterface, MessageTemplateData } from "../interfaces/message-template-result.interface";
import { MessageTemplateSingleResultInterface } from "../interfaces/message-template-single-result.interface";

export interface GetAllMessageTemplateParam {
  page?: number;
  size?: number;
}

export const GetAllMessageTemplate = async (params: GetAllMessageTemplateParam): Promise<MessageTemplateResultInterface> => {
  try {
    let response = await ApiCrm.get(
      '/message-templates',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: MessageTemplateResultInterface = a.response?.data;
    return response;
  }
}

export const ShowMessageTemplate = async (id: string): Promise<MessageTemplateSingleResultInterface> => {
  try {
    let response = await ApiCrm.get(
      '/message-templates/' + id,
    );
    return response.data as MessageTemplateSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: MessageTemplateSingleResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitMessageTemplate = async (form: MessageTemplateData): Promise<MessageTemplateSingleResultInterface> => {
  try {
    let response = await ApiCrm.post(
      '/message-templates',
      form
    );
    return response.data as MessageTemplateSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: MessageTemplateSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateMessageTemplate = async (id: string, form: MessageTemplateData): Promise<MessageTemplateSingleResultInterface> => {
  try {
    let response = await ApiCrm.put(
      '/message-templates/' + id,
      form
    );
    return response.data as MessageTemplateSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: MessageTemplateSingleResultInterface = a.response?.data;
    return response;
  }
}

export const DeleteMessageTemplate = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete(
      '/message-templates/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}

export const BulkSortingMessageTemplate = async (ids: string[], sorts: number[]): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put(
      '/message-templates-sorting',
      {
        ids: ids,
        sorts: sorts
      }
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}

export const GetTotalMessageTemplate = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.get(
      '/total-active-message-templates'
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}