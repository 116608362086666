import {useEffect, useState} from 'react'
import {useParams} from 'react-router'
import {number} from 'yup'

import TotalDonationAffiliatorCardComponent from '../../../auth/affiliator-management/components/total-donation-affiliator-card.component'
import DonationHistoryAffiliatorComponent from '../../../program/program-affiliate/components/donation-history-affiliator.component'
import {useDashboardAffiliateStore} from '../../../program/program-affiliate/states/program-affiliate.state'
import {useAffiliatorCompanyStore} from '../states/affiliator-state'
import {BarChart} from './bar-chart.component'
import DonationSegmentChart from './donation-chart.component'
import StepBar from './step-bar.component'

const AffiliatorDetailScreen = () => {
  const [amount, setAmount] = useState<number>(0)
  const params = useParams()
  const {totalDonationMember, getTotalDoantionMember, totalDonationMemberLoading} =
    useAffiliatorCompanyStore()

  const {
    getTotalDonationAffiliateByTransfer,
    totalDonationAffiliateByTransfer,
    totalDonationAffiliateByTunai,
    getTotalDonationAffiliateByTunai,
  } = useDashboardAffiliateStore()

  useEffect(() => {
    getTotalDoantionMember(params?.userId)
  }, [])

  useEffect(() => {
    setAmount(
      totalDonationAffiliateByTransfer + totalDonationAffiliateByTunai + totalDonationMember
    )
  }, [totalDonationAffiliateByTransfer])

  useEffect(() => {
    getTotalDonationAffiliateByTransfer(params?.userId)
    console.log(totalDonationAffiliateByTransfer)
  }, [])

  useEffect(() => {
    getTotalDonationAffiliateByTunai(params?.userId)
  }, [])

  const data = [
    ['Year', 'Transfer', 'Tunai', 'Affiliate'],
    ['2023', totalDonationAffiliateByTransfer, totalDonationAffiliateByTunai, totalDonationMember],
    ['2024', 0, 0, 0],
    ['2025', 0, 0, 0],
  ]
  return (
    <>
      <div className='row gy-5 g-xl-8 mb-5'>
        <TotalDonationAffiliatorCardComponent userId={params?.userId} />
        <div className='d-flex flex-column flex-md-row flex-sm-row justify-content-between gap-5'>
          <DonationSegmentChart />
          <BarChart data={data} />
        </div>
        <div className='mb-10'>
          <StepBar amount={amount} />
        </div>
        <div className='mb-10'>
          <DonationHistoryAffiliatorComponent userId={params?.userId} />
        </div>
      </div>
    </>
  )
}

export default AffiliatorDetailScreen
