import {type} from 'os'
import {createContext, useEffect, useState} from 'react'
import {GetTotalDonationMember} from '../providers/affiliator-provider'
import {
  GetTotalDonationAffiliateByTransfer,
  GetTotalDonationAffiliateByTunai,
} from '../../../program/program-affiliate/providers/program-affiliate.provider'
export type TotalDonationContextState = {
  totalDonation?: number
}

export const TotalDonationMemberContext = createContext<TotalDonationContextState | null>(null)

interface TotalDonationContextProviderProps {
  userId: string
  children: React.ReactNode
}

export const TotalDonationMemberContextProvider = ({
  userId,
  children,
}: TotalDonationContextProviderProps) => {
  const [totalDonation, setTotalDonation] = useState<number>()

  const getTotal = async (userId: string) => {
    if (userId !== '') {
      const affiliate = await GetTotalDonationMember(userId)
      const transfer = await GetTotalDonationAffiliateByTransfer(userId)
      const tunai = await GetTotalDonationAffiliateByTunai(userId)
      if (affiliate.status && transfer.status && tunai.status) {
        setTotalDonation(affiliate.data + transfer.data + tunai.data)
      }
    }
  }

  useEffect(() => {
    getTotal(userId)
  }, [userId])

  return (
    <TotalDonationMemberContext.Provider
      value={{
        totalDonation,
      }}
    >
      {children}
    </TotalDonationMemberContext.Provider>
  )
}
