export const generateStatusBadge = (priority: string) => {
  switch (priority) {
    case 'Not Started':
      return <span className='badge badge-light'>Not Started</span>
    case 'In Progress':
      return <span className='badge badge-primary'>In Progress</span>
    case 'waiting':
      return <span className='badge badge-warning'>Waiting</span>
    case 'Completed':
      return <span className='badge badge-success'>Completed</span>
    case 'executed':
      return <span className='badge badge-success'>Executed</span>
    case 'success':
      return <span className='badge badge-success'>Success</span>
    case 'Deferred':
      return <span className='badge badge-danger'>Deferred</span>
    case 'failed':
      return <span className='badge badge-danger'>Failed</span>
    case 'capture':
      return <span className='badge badge-success'>Capture</span>
    case 'settlement':
      return <span className='badge badge-success'>Settlement</span>
    case 'pending':
      return <span className='badge badge-primary'>Pending</span>
    case 'sending':
      return <span className='badge badge-primary'>Sending</span>
    case 'deny':
      return <span className='badge badge-primary'>Deny</span>
    case 'cancel':
      return <span className='badge badge-primary'>Cancel</span>
    case 'expire':
      return <span className='badge badge-primary'>Expire</span>
    case 'refund':
      return <span className='badge badge-primary'>Refund</span>
    case 'partial_refund':
      return <span className='badge badge-primary'>Partial_refund</span>
    case 'a':
      return <span className='badge badge-primary'>Authorize</span>
    default:
      return <span className='badge badge-secondary'>-</span>
  }
}
