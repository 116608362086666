import {FormInput} from '../../../interfaces/form-action-interfaces'
import {FileUploadV2, FileUploadVideoV2} from './file-upload-v2.component'
import {
  CheckboxInput,
  Input,
  InputCurrency,
  InputDatePicker,
  InputDateTimePicker,
  InputTextArea,
  MultipleInputText,
  SelectInput,
  SelectWithText,
  TagInput,
} from './input.component'
import {NumberFormatValues, SourceInfo} from 'react-number-format'
import {RichText} from './ckeditor/rich-text'
import {FileUploadMulti, FileUploadMultiVideo} from './file-upload-multi.component'
import {SingleFileUploadResult} from '../../uploads/interfaces/single-file-upload-result'
import CodeEditorComponent from './code-editor.component'
import {Tag} from 'react-tag-input'

interface FormComponentProps {
  forms: FormInput[]
  setForms: (index: number, value: any) => void
  uploadFileCb?: (result: SingleFileUploadResult) => void
  selectWithTextCb?: (index: number, name: string, value: string) => void
  selectWithTextInputChangeCb?: (index: number, name: string, value: string) => void
}

const FormsComponent: React.FC<FormComponentProps> = ({
  forms,
  setForms,
  uploadFileCb,
  selectWithTextCb = (index: number, name: string, value: string) => {},
  selectWithTextInputChangeCb = (index: number, name: string, value: string) => {},
}) => {
  return (
    <>
      {forms?.length > 0 &&
        forms?.map((v, k) => {
          switch (v.type) {
            case 'file-upload-multi':
              return (
                <FileUploadMulti
                  key={k}
                  title={v.title}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  multiple={true}
                  onUploadDone={(e) => {
                    let uploads = []
                    for (const a of e.data) {
                      uploads.push(a.id)
                    }
                    setForms(k, uploads)
                  }}
                />
              )
            case 'file-upload-multi-video':
              return (
                <FileUploadMultiVideo
                  key={k}
                  title={v.title}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  multiple={true}
                  onUploadDone={(e) => {
                    let uploads = []
                    for (const a of e.data) {
                      uploads.push(a.id)
                    }
                    setForms(k, uploads)
                  }}
                />
              )
            case 'file-upload-v2':
              return (
                <FileUploadV2
                  key={k}
                  title={v.title}
                  multiple={v.isMulti}
                  description={v.placeholder}
                  name={v.name}
                  onUploadDone={(e) => {
                    setForms(k, e.data?.id)
                    uploadFileCb(e)
                  }}
                  value={v.value}
                  disabled={v.disabled}
                  isImage={v.isImage}
                  accept={v.accept}
                  required={v.required}
                  onChangeFile={(e) => {}}
                />
              )
            case 'file-upload-video-v2':
              return (
                <FileUploadVideoV2
                  key={k}
                  title={v.title}
                  multiple={v.isMulti}
                  description={v.placeholder}
                  name={v.name}
                  onUploadDone={(e) => {
                    setForms(k, e.data?.id)
                  }}
                  value={v.value}
                  disabled={v.disabled}
                  isVideo={v.isVideo}
                />
              )
            case 'text':
              return (
                <Input
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  hidden={v.hidden}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChange={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                />
              )
            case 'multiple-text':
              return (
                <MultipleInputText
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  hidden={v.hidden}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  values={v.values}
                  disabled={v.disabled}
                  required={v.required}
                  onChangeValues={(e) => {
                    setForms(k, e)
                  }}
                  size={v.size}
                />
              )
            case 'password':
              return (
                <Input
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChange={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                />
              )
            case 'number':
              return (
                <Input
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={'text'}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChange={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                />
              )
            case 'currency':
              return (
                <InputCurrency
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={'text'}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onValueChange={(values: NumberFormatValues, sourceInfo: SourceInfo) => {
                    setForms(k, values.floatValue)
                  }}
                  size={v.size}
                />
              )
            case 'email':
              return (
                <Input
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChange={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                />
              )
            case 'date':
              return (
                <InputDatePicker
                  id={v.id}
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChangeDate={(e) => {
                    setForms(k, e)
                  }}
                />
              )
            case 'datetime':
              return (
                <InputDateTimePicker
                  id={v.id}
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChangeDate={(e) => {
                    setForms(k, e)
                  }}
                />
              )
            case 'datetime-blast':
              return (
                <InputDateTimePicker
                  id={v.id}
                  key={k}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChangeDate={(e) => {
                    setForms(k, e)
                  }}
                />
              )
            case 'text-area':
              return (
                <InputTextArea
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  onChangeTextArea={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                />
              )
            case 'select':
              return (
                <SelectInput
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  selectData={v.selectData}
                  onChangeSelect={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                  hidden={v.hidden}
                />
              )
            case 'select-number':
              return (
                <SelectInput
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  selectData={v.selectData}
                  onChangeSelect={(e) => {
                    setForms(k, e.target.value)
                  }}
                  size={v.size}
                />
              )
            case 'select-with-text':
              return (
                <SelectWithText
                  key={k}
                  title={v.title}
                  name={v.name}
                  selectData={v.selectData}
                  type={v.type}
                  value={v.value}
                  disabled={v.disabled}
                  onChangeSelectWithText={(e) => {
                    setForms(k, e)
                    selectWithTextCb(k, v.name, e.value)
                  }}
                  onInputChange={(e) => {
                    // v.onInputChange(e)
                    selectWithTextInputChangeCb(k, v.name, e)
                  }}
                  id={v.id}
                  size={v.size}
                  required={v.required}
                />
              )
            case 'select-with-text-multi':
              return (
                <SelectWithText
                  id={v.id}
                  key={k}
                  title={v.title}
                  name={v.name}
                  selectData={v.selectData}
                  type={v.type}
                  value={v.value}
                  disabled={v.disabled}
                  isMulti={v.isMulti}
                  onChangeSelectWithText={(e) => {
                    console.log(e)
                    setForms(k, e)
                  }}
                  onInputChange={v.onInputChange}
                  required={v.required}
                />
              )
            case 'checkbox':
              return (
                <CheckboxInput
                  key={k}
                  id={v.id}
                  title={v.title}
                  type={v.type}
                  placeholder={v.placeholder}
                  name={v.name}
                  value={v.value}
                  disabled={v.disabled}
                  required={v.required}
                  selectData={v.selectData}
                  onChange={(e) => {
                    setForms(k, e.target.checked)
                  }}
                  size={v.size}
                  hidden={v.hidden}
                />
              )
            case 'rich-text':
              return (
                <RichText
                  key={k}
                  value={v.value}
                  name={v.name}
                  title={v.title}
                  disabled={v.disabled}
                  onChange={(event: any, editor: any) => {
                    const data = editor.getData()
                    setForms(k, data)
                    console.log({event, editor, data})
                  }}
                  onBlur={(event: any, editor: any) => {
                    console.log('Blur.', editor)
                  }}
                  onFocus={(event: any, editor: any) => {
                    console.log('Focus.', editor)
                  }}
                  required={v.required}
                />
              )
            case 'code-editor':
              return (
                <CodeEditorComponent
                  key={k}
                  value={v.value}
                  name={v.name}
                  title={v.title}
                  placeholder={v.placeholder}
                  type={v.type}
                  onChangeCodeEditor={(value, event) => {
                    setForms(k, value)
                  }}
                  id={v.id}
                />
              )
            case 'tags':
              return (
                <TagInput
                  id={v.id}
                  key={k}
                  title={v.title}
                  name={v.name}
                  type={v.type}
                  tags={v.value}
                  suggestions={[]}
                  handleAddition={(tag) => {
                    setForms(k, [...v.value, tag])
                  }}
                  handleDelete={(i) => {
                    setForms(
                      k,
                      v.value.filter((tag: Tag, index: number) => index !== i)
                    )
                  }}
                  handleDrag={(tag, currPos, newPos) => {
                    const newTags = v.value.slice()

                    newTags.splice(currPos, 1)
                    newTags.splice(newPos, 0, tag)

                    // re-render
                    setForms(k, newTags)
                  }}
                />
              )
            default:
              return <div key={k}></div>
          }
        })}
    </>
  )
}

export default FormsComponent
