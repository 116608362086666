/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

 import {FC, Suspense, useEffect} from 'react'
 import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
 import {PrivateRoutes} from './PrivateRoutes'
 import {ErrorsPage} from '../modules/errors/ErrorsPage'
 import {Logout, AuthPage, useAuth} from '../modules/auth'
 import {App} from '../App'
import AxiosInterceptorNavigate from '../modules/auth/axios-interceptor.screen'
import { useSocketStore } from '../modules/sockets/socket.state'
import { toast } from 'react-toastify'
import InvoiceComponent from '../modules/apps/program/donation/components/invoice.component'
import { LayoutSplashScreen } from '../../_metronic/layout/core'
 
 /**
  * Base URL of the website.
  *
  * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
  */
 const {PUBLIC_URL} = process.env
 
 const AppRoutes: FC = () => {
   const {currentUser} = useAuth();
   const { initSocket, socket } = useSocketStore();

   useEffect(() => {
     initSocket();
   }, [])

  //  useEffect(() => {
  //   if (socket !== undefined) {
  //     socket.on('new-message', ({ destinations }) => {
  //       toast.success('You have a new message');
  //     });
  //   }
  //  }, [socket])
   
   return (
     <BrowserRouter basename={PUBLIC_URL}>
       {<AxiosInterceptorNavigate/>}
       <Routes>
         <Route element={<App />}>
           <Route path='error/*' element={<ErrorsPage />} />
           <Route path='logout' element={<Logout />} />
           {currentUser ? (
             <>
               <Route path='/*' element={<PrivateRoutes />} />
               <Route index element={<Navigate to='/dashboard' />} />
             </>
           ) : (
             <>
               <Route path='auth/*' element={<AuthPage />} />
               <Route path='*' element={<Navigate to='/auth' />} />
             </>
           )}
         </Route>
       </Routes>
     </BrowserRouter>
   )
 }
 
 export {AppRoutes}
 