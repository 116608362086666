import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {ProgramSingleResultInterface} from '../interfaces/program-single-result.interface'
import {ProgramData, ProgramResultInterface} from '../interfaces/program-result.interface'
import {
  GetAllProgramParam,
  GetAllProgram,
  DeleteProgram,
  SubmitProgram,
  UpdateProgram,
} from '../providers/program.provider'
import moment from 'moment'

interface ProgramState {
  programLoading: boolean
  programItems: ProgramData[]
  programSelectDatas: SelectData[]
  programModel?: ProgramResultInterface
  getPrograms: (params: GetAllProgramParam) => void
  onDelete: (id: string, params: GetAllProgramParam) => void
}

export const useProgramStore = create<ProgramState>((set, get) => ({
  programLoading: false,
  programItems: [],
  programSelectDatas: [],
  programModel: undefined,
  getPrograms: async (params: GetAllProgramParam) => {
    set({programLoading: true})
    const response = await GetAllProgram(params)
    set({programModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.title,
            value: item.id,
          })
        }
        set({
          programItems: response.data.items,
          programSelectDatas: transformDatas,
        })
      } else {
        set({
          programItems: [],
          programSelectDatas: [],
        })
      }
    } else {
      set({
        programItems: [],
        programSelectDatas: [],
      })
    }
    set({programLoading: false})
  },
  onDelete: async (id: string, params: GetAllProgramParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({programLoading: true})
      const response = await DeleteProgram(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getPrograms(params)
      set({programLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface ProgramFormState {
  field?: ProgramData
  setField?: (item: ProgramData) => void
  forms?: FormInput[]
  addForms?: (form: FormInput) => void
  hiddenForm?: (index: number, value: boolean) => void
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const useProgramFormStore = create<ProgramFormState>((set, get) => ({
  field: undefined,
  setField: (item: ProgramData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  hiddenForm: (index: number, value: boolean) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      hidden: value,
    }
    set({forms: forms})
  },
  addForms: (form: FormInput) => {
    const forms = [...get().forms]
    forms.push(form)
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'banner',
        title: 'Banner',
        placeholder: 'Choose file...',
        type: 'file-upload-v2',
        name: 'banner',
        value: get().field?.banner ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
      {
        id: 'Images',
        title: 'Images',
        placeholder: 'Choose file...',
        type: 'file-upload-multi',
        name: 'images',
        isMulti: true,
        value: get().field?.images ?? [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
      },
      {
        id: 'title',
        title: 'Title',
        placeholder: 'Title...',
        type: 'text',
        name: 'title',
        value: get().field?.title ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'excerpt',
        title: 'Excerpt',
        placeholder: 'Excerpt...',
        type: 'text',
        name: 'excerpt',
        value: get().field?.excerpt ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'description',
        title: 'Description',
        placeholder: 'Description...',
        type: 'rich-text',
        name: 'description',
        value: get().field?.description ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'program_category_id',
        title: 'Program Category',
        placeholder: 'Program Category...',
        type: 'select-with-text',
        name: 'program_category_id',
        value: {
          label: get().field?.program_category?.name,
          value: get().field?.program_category?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'donation_type_id',
        title: 'Donation Type',
        placeholder: 'Donation Type...',
        type: 'select-with-text',
        name: 'donation_type_id',
        value: {
          label: get().field?.donation_type?.name,
          value: get().field?.donation_type?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },

      {
        id: 'program_type',
        title: 'Program Type',
        placeholder: 'Program Type...',
        type: 'select-with-text',
        name: 'program_type',
        value: {
          label: get().field?.program_type,
          value: get().field?.program_type,
        },
        selectData: [
          {label: 'Donasi', value: 'Donasi'},
          {label: 'Kurban', value: 'Kurban'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_publish',
        title: 'Publish?',
        placeholder: 'Publish?',
        type: 'checkbox',
        name: 'is_publish',
        value: get().field?.is_publish ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
        hidden: false,
      },
      {
        id: 'donation_target',
        title: 'Donation Target',
        placeholder: 'Donation Target...',
        type: 'currency',
        name: 'donation_target',
        value: get().field?.donation_target ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'price',
        title: 'Price (If Program Type is Kurban)',
        placeholder: 'Price (If Program Type is Kurban)...',
        type: 'currency',
        name: 'price',
        value: get().field?.price ?? 0,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'started_at',
        title: 'Start Date',
        placeholder: 'Start Date',
        type: 'date',
        name: 'started_at',
        value: moment(get().field?.started_at).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'finished_at',
        title: 'Finish Date',
        placeholder: 'Finish Date',
        type: 'date',
        name: 'finished_at',
        value: moment(get().field?.finished_at).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'section_one_active',
        title: 'Section One Active?',
        placeholder: 'Section One Active?',
        type: 'checkbox',
        name: 'section_one_active',
        value: get().field?.section_one_active,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'section_two_active',
        title: 'Section Two Active?',
        placeholder: 'Section Two Active?',
        type: 'checkbox',
        name: 'section_two_active',
        value: get().field?.section_two_active,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'tags',
        title: 'Tags',
        placeholder: 'Tag name...',
        type: 'tags',
        name: 'tags',
        value: get().field?.tags ?? [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_footer',
        title: 'Footer On',
        placeholder: 'Footer?',
        type: 'checkbox',
        name: 'is_footer',
        value: get().field?.is_footer ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'slug',
        title: 'Link (Slug)',
        placeholder: 'Kosongkan jika ingin link disesuaikan dengan title...',
        type: 'text',
        name: 'slug',
        value: get().field?.slug ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      // {
      //   id: 'branch_id',
      //   title: 'Cabang',
      //   placeholder: 'Cabang...',
      //   type: 'select-with-text',
      //   name: 'branch_id',
      //   value: {
      //     label: get().field?.branch_name ?? '',
      //     value: get().field?.branch_id ?? '',
      //   },
      //   selectData: [],
      //   disabled: formParam.action === FormAction.VIEW ? true : false,
      //   required: false,
      // },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: ProgramSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitProgram(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateProgram(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
