import create from 'zustand'
import {
  GetAllWalletPointParam,
  GetTotalUser,
  GetTotalPointShare,
  GetTotalWalletCash
} from '../../wallet/wallet-management/providers/wallet-point.provider'

interface DashboardState {
  // filter date
  startDate: Date
  endDate: Date
  setStartDate: (date: Date) => void
  setEndDate: (date: Date) => void

  // total customer
  totalUserLoading: boolean
  totalUser: number
  getTotalUser: (params: GetAllWalletPointParam) => void

  // total point share
  totalPointShareLoading: boolean
  totalPointShare: number
  getTotalPointShare: (params: GetAllWalletPointParam) => void

  // total wallet cash
  totalWalletCashLoading: boolean
  totalWalletCash: number
  getTotalWalletCash: (params: GetAllWalletPointParam) => void
}

export const useDashboardStore = create<DashboardState>((set, get) => ({
  startDate: undefined,
  endDate: undefined,
  setStartDate: (date: Date) => {
    set({startDate: date})
  },
  setEndDate: (date: Date) => {
    set({endDate: date})
  },
  totalUserLoading: false,
  totalUser: 0,
  getTotalUser: async (params: GetAllWalletPointParam) => {
    set({totalUserLoading: true})
    const response = await GetTotalUser(params)
    if (response.status) {
      set({totalUser: response.data as number})
    } else {
      set({totalUser: 0})
    }
    setTimeout(() => {
      set({totalUserLoading: false})
    }, 2000)
  },
  totalPointShareLoading: false,
  totalPointShare: 0,
  getTotalPointShare: async (params: GetAllWalletPointParam) => {
    set({totalPointShareLoading: true})
    const response = await GetTotalPointShare(params)
    if (response.status) {
      set({totalPointShare: response.data as number})
    } else {
      set({totalPointShare: 0})
    }
    setTimeout(() => {
      set({totalPointShareLoading: false})
    }, 2000)
  },
  totalWalletCashLoading: false,
  totalWalletCash: 0,
  getTotalWalletCash: async (params: GetAllWalletPointParam) => {
    set({totalWalletCashLoading: true})
    const response = await GetTotalWalletCash(params)
    if (response.status) {
      set({totalWalletCash: response.data as number})
    } else {
      set({totalWalletCash: 0})
    }
    setTimeout(() => {
      set({totalWalletCashLoading: false})
    }, 2000)
  },
}))
