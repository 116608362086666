import {toast} from 'react-toastify'
import {create} from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'

import {DonationSingleResultInterface} from '../interfaces/donation-single-result.interface'
import {DonationData, DonationResultInterface} from '../interfaces/donation-result.interface'
import {
  GetAllDonationParam,
  GetAllDonation,
  DeleteDonation,
  SubmitDonation,
  UpdateDonation,
  ShowDonation,
  GetAllDonationByInternalMemo,
  GetAllDonationByWorkOder,
  GetAllDonationByCustomer,
  GetAllDonationByAffiliator,
} from '../providers/donation.provider'
import moment from 'moment'

interface NavList {
  name: string
  reference: string
}

interface DonationState {
  donationLoading: boolean
  donationItems: DonationData[]
  donationSelectDatas: SelectData[]
  donationModel?: DonationResultInterface
  getDonation: (params: GetAllDonationParam) => void
  getDonationByCustomer?: (params: GetAllDonationParam) => void
  getDonationByInternalMemo: (internalMemoId: string, params: GetAllDonationParam) => void
  getDonationByWorkOrder: (workOrderId: string, params: GetAllDonationParam) => void
  onDelete: (id: string, params: GetAllDonationParam) => void
  navList: NavList[]
  activeNavIndex: number
  setActiveNavIndex: (index: number) => void
  selectedDonation: DonationData
  setSelectedDonation: (data: DonationData) => void
  getDonationDetail: (id: string) => void

  //all donation by affiliator
  donationByAffiliatorLoading: boolean
  donationByAffiliatorItems: DonationData[]
  donationByAffiliatorSelectDatas: SelectData[]
  donationByAffiliatorModel?: DonationResultInterface
  getDonationByAffiliator: (id: string, params: GetAllDonationParam) => void
}

export const useDonationStore = create<DonationState>((set, get) => ({
  activeNavIndex: 0,
  setActiveNavIndex: (index: number) => {
    set({activeNavIndex: index})
  },
  navList: [
    {
      name: 'Retails',
      reference: 'customers',
    },
    {
      name: 'Corporate',
      reference: 'customers_corporate',
    },
    {
      name: 'Community',
      reference: 'customers_community',
    },
  ],
  donationLoading: false,
  donationItems: [],
  donationSelectDatas: [],
  donationModel: undefined,
  getDonation: async (params: GetAllDonationParam) => {
    set({
      donationLoading: true,
      donationItems: [],
      donationSelectDatas: [],
    })
    const response = await GetAllDonation(params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  getDonationByCustomer: async (params: GetAllDonationParam) => {
    set({donationLoading: true})
    const response = await GetAllDonationByCustomer(params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  getDonationByInternalMemo: async (internalMemoId: string, params: GetAllDonationParam) => {
    set({donationLoading: true})
    const response = await GetAllDonationByInternalMemo(internalMemoId, params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  getDonationByWorkOrder: async (workOderId: string, params: GetAllDonationParam) => {
    set({donationLoading: true})
    const response = await GetAllDonationByWorkOder(workOderId, params)
    set({donationModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationItems: response.data.items,
          donationSelectDatas: transformDatas,
        })
      } else {
        set({
          donationItems: [],
          donationSelectDatas: [],
        })
      }
    } else {
      set({
        donationItems: [],
        donationSelectDatas: [],
      })
    }
    set({donationLoading: false})
  },
  onDelete: async (id: string, params: GetAllDonationParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({donationLoading: true})
      const response = await DeleteDonation(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getDonation(params)
      set({donationLoading: false})
    }
  },
  selectedDonation: undefined,
  setSelectedDonation: (data: DonationData) => {
    set({selectedDonation: data})
  },
  getDonationDetail: async (id: string) => {
    const response = await ShowDonation(id)
    if (response.status) {
      set({selectedDonation: response.data})
    }
  },

  donationByAffiliatorLoading: false,
  donationByAffiliatorItems: [],
  donationByAffiliatorSelectDatas: [],
  donationByAffiliatorModel: undefined,
  getDonationByAffiliator: async (id: string, params: GetAllDonationParam) => {
    set({donationByAffiliatorLoading: true})
    const response = await GetAllDonationByAffiliator(id, params)
    set({donationByAffiliatorModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.id,
            value: item.id,
          })
        }
        set({
          donationByAffiliatorItems: response.data.items,
          donationByAffiliatorSelectDatas: transformDatas,
        })
      } else {
        set({
          donationByAffiliatorItems: [],
          donationByAffiliatorSelectDatas: [],
        })
      }
    } else {
      set({
        donationByAffiliatorItems: [],
        donationByAffiliatorSelectDatas: [],
      })
    }
    set({donationByAffiliatorLoading: false})
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface DonationFormState {
  field?: DonationData
  setField?: (item: DonationData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: (reference: string) => void
}

export const useDonationFormStore = create<DonationFormState>((set, get) => ({
  field: undefined,
  setField: (item: DonationData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'customer_id',
        title: 'Donatur',
        placeholder: 'Donatur...',
        type: 'select-with-text',
        name: 'customer_id',
        value: {
          label:
            get().field?.customer?.values?.find(
              (el) =>
                el.name === 'nama-lengkap' ||
                el.name === 'nama-perusahaan' ||
                el.name === 'nama-komunitas'
            )?.value ?? '-',
          value: get().field?.customer?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'transfer_date',
        title: 'Waktu Transfer',
        placeholder: 'Waktu Transfer...',
        type: 'datetime-blast',
        name: 'transfer_date',
        value: moment(get().field?.transfer_date).toDate() ?? moment(Date.now()).toDate(),
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'program_id',
        title: 'Program',
        placeholder: 'Program...',
        type: 'select-with-text',
        name: 'program_id',
        value: {
          label: get().field?.program?.title ?? '-',
          value: get().field?.program?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'payment_id',
        title: 'Payment Method',
        placeholder: 'Payment Method...',
        type: 'select-with-text',
        name: 'payment_id',
        value: {
          label: `${get().field?.payment?.name ?? '-'} ${get().field?.payment?.description ?? '-'}`,
          value: get().field?.payment?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'status',
        title: 'Status',
        placeholder: 'Status...',
        type: 'select',
        name: 'status',
        value: get().field?.status ?? 'waiting',
        selectData: [
          {label: 'capture', value: 'capture'},
          {label: 'settlement', value: 'settlement'},
          {label: 'pending', value: 'pending'},
          {label: 'deny', value: 'deny'},
          {label: 'cancel', value: 'cancel'},
          {label: 'expire', value: 'expire'},
          {label: 'refund', value: 'Refund'},
          {label: 'partial_refund', value: 'partial_refund'},
          {label: 'authorize', value: 'authorize'},
          {label: 'waiting', value: 'waiting'},
        ],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
        hidden: true,
      },
      {
        id: 'amount',
        title: 'Amount',
        placeholder: 'Amount...',
        type: 'currency',
        name: 'amount',
        value: get().field?.amount ?? 0,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'channel_id',
        title: 'Channel',
        placeholder: 'Channel...',
        type: 'select-with-text',
        name: 'channel_id',
        value: {
          label: get().field?.channel?.name,
          value: get().field?.channel?.id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'depositor',
        title: 'Petugas Penerima',
        placeholder: 'Petugas Penerima...',
        type: 'text',
        name: 'depositor',
        value: get().field?.depositor ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: false,
        hidden: false,
      },
      {
        id: 'proof_of_payment',
        title: 'Bukti Transfer',
        placeholder: 'Choose file...',
        type: 'file-upload-v2',
        name: 'proof_of_payment',
        value: get().field?.proof_of_payment ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
      {
        id: 'fundraiser_id',
        title: 'Fundraiser',
        placeholder: 'Fundraiser...',
        type: 'select-with-text',
        name: 'fundraiser_id',
        selectData: [],
        value: {
          label: `${get().field?.aff_code ?? ''} - ${get().field?.aff_name ?? ''}`,
          value: get().field?.aff_id,
        },
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'remarks',
        title: 'Note (Catatan CRM)',
        placeholder: 'Note (Catatan CRM)...',
        type: 'text-area',
        name: 'remarks',
        value: get().field?.remarks ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        isImage: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async (reference: string) => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    form = {
      ...form,
      customer_type: reference,
      // status: 'waiting',
    }

    let res: DonationSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitDonation(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdateDonation(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
