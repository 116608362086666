import {toast} from 'react-toastify'
import create from 'zustand'
import {generateFormLogic} from '../../../../../../_metronic/helpers/generate-form-logic'
import {
  FormAction,
  FormInput,
  FormProps,
  SelectData,
} from '../../../../../interfaces/form-action-interfaces'
import {PaymentSingleResultInterface} from '../interfaces/payment-single-result.interface'
import {PaymentData, PaymentResultInterface} from '../interfaces/payment-result.interface'
import {
  GetAllPaymentParam,
  GetAllPayment,
  DeletePayment,
  SubmitPayment,
  UpdatePayment,
} from '../providers/payment.provider'

interface PaymentState {
  paymentLoading: boolean
  paymentItems: PaymentData[]
  paymentSelectDatas: SelectData[]
  paymentModel?: PaymentResultInterface
  getPayments: (params: GetAllPaymentParam) => void
  onDelete: (id: string, params: GetAllPaymentParam) => void
}

export const usePaymentStore = create<PaymentState>((set, get) => ({
  paymentLoading: false,
  paymentItems: [],
  paymentSelectDatas: [],
  paymentModel: undefined,
  getPayments: async (params: GetAllPaymentParam) => {
    set({paymentLoading: true})
    const response = await GetAllPayment(params)
    set({paymentModel: response})
    if (response.status) {
      if (response.data.items.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: `${item.name} - ${item.account_number} -${item.description}`,
            value: item.id,
          })
        }
        set({
          paymentItems: response.data.items,
          paymentSelectDatas: transformDatas,
        })
      } else {
        set({
          paymentItems: [],
          paymentSelectDatas: [],
        })
      }
    } else {
      set({
        paymentItems: [],
        paymentSelectDatas: [],
      })
    }
    set({paymentLoading: false})
  },
  onDelete: async (id: string, params: GetAllPaymentParam) => {
    const confirm = window.confirm('Apakah anda yakin ingin menghapus data ini?')
    if (confirm === true) {
      set({paymentLoading: true})
      const response = await DeletePayment(id)
      if (response.status) {
        toast.success(response.message)
      } else {
        toast.error(response.message)
      }
      get().getPayments(params)
      set({paymentLoading: false})
    }
  },
}))

const formInitialState = {
  loading: false,
  forms: [],
  formParam: undefined,
  formLoading: undefined,
  submitDone: undefined,
}

export interface PaymentFormState {
  field?: PaymentData
  setField?: (item: PaymentData) => void
  forms?: FormInput[]
  setForms?: (index: number, value: any) => void
  generateForms: (formParam: FormProps) => void
  setFormSelectData: (index: number, selectData: SelectData[]) => void
  formParam: FormProps
  reset: () => void
  formLoading?: boolean
  submitDone?: boolean
  onSubmit: () => void
}

export const usePaymentFormStore = create<PaymentFormState>((set, get) => ({
  field: undefined,
  setField: (item: PaymentData) => {
    set({field: item})
  },
  formParam: undefined,
  setForms: (index: number, value: any) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      value: value,
    }
    set({forms: forms})
  },
  generateForms: (formParam: FormProps) => {
    set({formParam: formParam})
    let forms: FormInput[] = []
    forms = [
      {
        id: 'name',
        title: 'Name',
        placeholder: 'Name...',
        type: 'text',
        name: 'name',
        value: get().field?.name ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'account_number',
        title: 'Account Number',
        placeholder: 'Account Number...',
        type: 'text',
        name: 'account_number',
        value: get().field?.account_number ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'description',
        title: 'Description',
        placeholder: 'Description...',
        type: 'text',
        name: 'description',
        value: get().field?.description ?? '',
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'is_active',
        title: 'Is Active',
        placeholder: 'Is Active...',
        type: 'checkbox',
        name: 'is_active',
        value: get().field?.is_active ?? false,
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'province_id',
        title: 'Provinsi',
        placeholder: 'Provinsi...',
        type: 'select-with-text',
        name: 'province_id',
        value: {
          label: get().field?.province?.name,
          value: get().field?.province?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'city_id',
        title: 'Kota/Kabupaten',
        placeholder: 'Kota/Kabupaten...',
        type: 'select-with-text',
        name: 'city_id',
        value: {
          label: get().field?.city?.name,
          value: get().field?.city?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'district_id',
        title: 'Kecamatan',
        placeholder: 'Kecamatan...',
        type: 'select-with-text',
        name: 'district_id',
        value: {
          label: get().field?.district?.name,
          value: get().field?.district?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
      {
        id: 'subdistrict_id',
        title: 'Kelurahan',
        placeholder: 'Kelurahan...',
        type: 'select-with-text',
        name: 'subdistrict_id',
        value: {
          label: get().field?.subdistrict?.name,
          value: get().field?.subdistrict?.id,
        },
        selectData: [],
        disabled: formParam.action === FormAction.VIEW ? true : false,
        required: true,
      },
    ]

    set({forms: forms})
  },
  reset: () => {
    set(formInitialState)
    return
  },
  onSubmit: async () => {
    let form = {}
    for (const item of get().forms) {
      form = {
        ...form,
        [item.name]: generateFormLogic(item.type, item.value),
      }
    }

    let res: PaymentSingleResultInterface = {}
    if (get().formParam.action === FormAction.CREATE) res = await SubmitPayment(form)
    if (get().formParam.action === FormAction.UPDATE)
      res = await UpdatePayment(get().formParam?.id, form)

    console.log(res)
    if (res.status) {
      toast.success(res.message)
      set({submitDone: true, formLoading: false, field: undefined})
      return
    } else {
      toast.error(res.message)
      set({submitDone: false, formLoading: false})
      return
    }
  },
  setFormSelectData: (index: number, selectData: SelectData[]) => {
    const forms = [...get().forms]
    forms[index] = {
      ...forms[index],
      selectData: selectData,
    }
    set({forms: forms})
  },
}))
