import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdminBranch = () => {
  return (
    <>
      <SidebarMenuItem
        to='/apps/admin-branch-dashboard/dashboard'
        title='Dashboard'
        hasBullet={true}
      />
      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-management/programs'
          title='Campaign Management'
          hasBullet={true}
        />
        {/* <SidebarMenuItem
          to='/apps/program/news-management/news'
          title='News Management'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/apps/program/banner-management/banner'
          title='Banner Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/program/program-event-banner-management/program-event-banner'
          title='Program Event Banner Management'
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainAdminBranch
