import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdmin = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/crm'
        title='Wallet'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/finance/fin008.svg'
      >
        
        <SidebarMenuItem
          to='/apps/wallet/conversion-rate-management/conversions'
          title='Conversion Rate Management'
        />
        <SidebarMenuItemWithSub
          to='/apps/wallet/wallet-cash-management/wallets'
          title='Wallet Cash Management'
          >          
          <SidebarMenuItem
            to='/apps/wallet/wallet-cash-management/wallets/wallet-cashs'
            title='Wallet Cash'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/apps/wallet/wallet-cash-management/wallets/cash-status'
            title='Check Status Wallet Cash'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
        <SidebarMenuItemWithSub
          to='/apps/wallet/wallet-management/wallets'
          title='Wallet Point Management'
        >          
          <SidebarMenuItem
            to='/apps/wallet/wallet-management/wallets/wallet-points'
            title='Wallet Point'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/apps/wallet/wallet-management/wallets/point-status'
            title='Check Status Wallet Point'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainAdmin
