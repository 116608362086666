import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useFlowBuilderStore, useReactFlowStore} from '../states/flow-builder.state'
import {Handle, Node, NodeProps, Position, useReactFlow} from 'reactflow'
import {
  InputTextArea,
  SelectInput,
  SelectWithText,
} from '../../../../forms/components/input.component'
import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {FileUploadV2} from '../../../../forms/components/file-upload-v2.component'
import {CardNodeData} from './card-node-v2.component'
import {useProgramStore} from '../../../program/program/states/program.state'

const FlowBuilderFormDetailModalComponent = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_properties'
        role={'dialog'}
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <FlowBuilderFormDetaiHeader />
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              <FlowBuilderFormDataDetailFormWrapper />
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

const FlowBuilderFormDetaiHeader = () => {
  const {setSelectedNode} = useFlowBuilderStore()

  return (
    <div className='modal-header'>
      <h2 className='fw-bold'>Flow Detail</h2>

      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => {
          setSelectedNode(undefined)
        }}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
    </div>
  )
}

const FlowBuilderFormDataDetailFormWrapper = () => {
  const [flowNode, setFlowNode] = useState<Node<CardNodeData>>()
  const {setSelectedNode, selectedNode, onModifyFlowNode} = useFlowBuilderStore()
  const {modifyNode} = useReactFlowStore()

  const [message, setMessage] = useState<string>('')
  const [type, setType] = useState<string>('text')
  const [saveAs, setSaveAs] = useState<string>('')
  const [buttons, setButtons] = useState<string[]>([])
  const [lists, setLists] = useState<string[]>([])
  const [textBasedActions, setTextBasedActions] = useState<string[]>([])
  const [textBasedActionNumbers, setTextBasedActionNumbers] = useState<string[]>([])
  const [media, setMedia] = useState<string>('')
  const [program, setProgram] = useState<SelectData>()
  const [programs, setPrograms] = useState<SelectData[]>([])

  const {getPrograms, programSelectDatas} = useProgramStore()

  useEffect(() => {
    getPrograms({page: 0, size: 1000000})
  }, [])

  useEffect(() => {
    if (programSelectDatas?.length > 0) {
      setPrograms(programSelectDatas)
    }
  }, [programSelectDatas])

  const typeOptions: SelectData[] = [
    {
      value: 'text',
      label: 'text',
    },
    {
      value: 'buttons-with-media',
      label: 'buttons-with-media',
    },
    {
      value: 'buttons',
      label: 'buttons',
    },
    {
      value: 'lists',
      label: 'lists',
    },
    {
      value: 'text-based-actions',
      label: 'text based action',
    },
    {
      value: 'text-based-actions-with-media',
      label: 'text based action with media',
    },
  ]
  const saveAsOptions: SelectData[] = [
    {
      value: 'customer_name',
      label: 'customer_name',
    },
    {
      value: 'email',
      label: 'email',
    },
    {
      value: 'amount',
      label: 'amount',
    },
    {
      value: 'product',
      label: 'product',
    },
    {
      value: 'program',
      label: 'program',
    },
    {
      value: 'payment',
      label: 'payment',
    },
  ]

  useEffect(() => {
    if (selectedNode !== undefined) {
      setFlowNode(selectedNode)
      setMessage(selectedNode?.data?.message)
      setType(selectedNode?.data?.type)
      setButtons(selectedNode?.data?.buttons ?? [])
      setMedia(selectedNode?.data?.media)
      setLists(selectedNode?.data?.lists ?? [])
      setTextBasedActions(selectedNode?.data?.text_based_actions ?? [])
      setTextBasedActionNumbers(selectedNode?.data?.text_based_action_numbers ?? [])
      setSaveAs(selectedNode?.data?.save_as)
      setProgram({
        value: selectedNode?.data?.product_id,
        label: selectedNode?.data?.product_name,
      })
    }
  }, [selectedNode])

  return (
    <>
      <form>
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <InputTextArea
            title='Message'
            placeholder='Enter Message...'
            onChangeTextArea={(e) => {
              setMessage(e.target?.value)
            }}
            type={'text-area'}
            disabled={false}
            id={'message'}
            name={'message'}
            value={message ?? ''}
          />
          <SelectInput
            id='save_as'
            placeholder=''
            name='save_as'
            value={saveAs}
            selectData={saveAsOptions}
            disabled={false}
            required={true}
            onChangeSelect={(e) => {
              setSaveAs(e.target?.value)
            }}
            title={'Save As'}
            type={'select'}
          />
          <SelectWithText
            id={'program_id'}
            title={'Program'}
            name={'program_id'}
            type={'select-with-text'}
            selectData={programs}
            value={program}
            onChangeSelectWithText={(e) => {
              setProgram(e)
            }}
          />
          <SelectInput
            id='type'
            placeholder='type'
            name='type'
            value={type}
            selectData={typeOptions}
            disabled={false}
            required={true}
            onChangeSelect={(e) => {
              setType(e.target?.value)
            }}
            title={'Type'}
            type={'select'}
          />
          {type.includes('buttons') ? (
            <>
              {buttons?.map((item, index) => (
                <div key={index} className='input-group mb-5'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={`Button Name`}
                    aria-label={`Button Name`}
                    aria-describedby='basic-addon2'
                    value={item}
                    onChange={(e) => {
                      const items = [...buttons]
                      items[index] = e.target.value
                      setButtons(items)
                    }}
                  />
                  <span
                    className='input-group-text'
                    id='basic-addon2'
                    onClick={(e) => {
                      const items = [...buttons]
                      items.splice(index, 1)
                      setButtons(items)
                    }}
                  >
                    <i className='fas fa-trash fs-4 text-danger' />
                  </span>
                </div>
              ))}
              <button
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  setButtons((el) => [...el, ''])
                }}
              >
                {`+ Add Button`}
              </button>
            </>
          ) : (
            <></>
          )}

          {type.includes('lists') ? (
            <>
              {lists?.map((item, index) => (
                <div key={index} className='input-group mb-5'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={`List Name`}
                    aria-label={`List Name`}
                    aria-describedby='basic-addon2'
                    value={item}
                    onChange={(e) => {
                      const items = [...lists]
                      items[index] = e.target.value
                      setLists(items)
                    }}
                  />
                  <span
                    className='input-group-text'
                    id='basic-addon2'
                    onClick={(e) => {
                      const items = [...lists]
                      items.splice(index, 1)
                      setLists(items)
                    }}
                  >
                    <i className='fas fa-trash fs-4 text-danger' />
                  </span>
                </div>
              ))}
              <button
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  setLists((el) => [...el, ''])
                }}
              >
                {`+ Add List`}
              </button>
            </>
          ) : (
            <></>
          )}

          {type.includes('text-based-actions') ? (
            <>
              {textBasedActions?.map((item, index) => (
                <div key={index} className='input-group mb-5'>
                  {/* <span className='input-group-text'>@</span> */}
                  <input
                    type='text'
                    className='input-group-text w-25'
                    placeholder={`Text Name`}
                    aria-label={`Text Name`}
                    aria-describedby='basic-addon2'
                    value={textBasedActionNumbers[index]}
                    onChange={(e) => {
                      const items = [...textBasedActionNumbers]
                      items[index] = e.target.value
                      setTextBasedActionNumbers(items)
                    }}
                  />
                  <input
                    type='text'
                    className='form-control'
                    placeholder={`Text Name`}
                    aria-label={`Text Name`}
                    aria-describedby='basic-addon2'
                    value={item}
                    onChange={(e) => {
                      const items = [...textBasedActions]
                      items[index] = e.target.value
                      setTextBasedActions(items)
                    }}
                  />
                  <span
                    className='input-group-text'
                    id='basic-addon2'
                    onClick={(e) => {
                      const items = [...textBasedActions]
                      const items2 = [...textBasedActionNumbers]
                      items.splice(index, 1)
                      items2.splice(index, 1)
                      setTextBasedActions(items)
                      setTextBasedActionNumbers(items2)
                    }}
                  >
                    <i className='fas fa-trash fs-4 text-danger' />
                  </span>
                </div>
              ))}
              <button
                className='btn btn-primary'
                onClick={(e) => {
                  e.preventDefault()
                  setTextBasedActions((el) => [...el, ''])
                  setTextBasedActionNumbers((el) => [...el, '0'])
                }}
              >
                {`+ Add Text`}
              </button>
            </>
          ) : (
            <></>
          )}

          {type.includes('media') ? (
            <FileUploadV2
              title={'Media'}
              multiple={false}
              description={'Upload Media'}
              name={'media'}
              onUploadDone={(e) => {
                setMedia(e.data?.id)
              }}
              value={media}
              disabled={false}
              accept={'image/*'}
              required={type.includes('media') ? true : false}
              onChangeFile={(e) => {}}
            />
          ) : (
            <></>
          )}
        </div>

        <div className='text-center pt--15' style={{marginTop: 10}}>
          <button
            type='reset'
            onClick={() => setSelectedNode(undefined)}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            onClick={(e) => {
              e.preventDefault()
              const node: Node<CardNodeData> = {
                ...flowNode,
                data: {
                  ...flowNode.data,
                  message: message,
                  type: type,
                  buttons: buttons,
                  media: media,
                  lists: lists,
                  save_as: saveAs,
                  text_based_actions: textBasedActions,
                  text_based_action_numbers: textBasedActionNumbers,
                  product_id: program?.value,
                  product_name: program?.label,
                },
              }
              console.log('asdasd', node)
              setFlowNode(node)
              onModifyFlowNode(node)
              modifyNode(node)
              setSelectedNode(undefined)
            }}
          >
            <span className='indicator-label'>Submit</span>
          </button>
        </div>
      </form>
    </>
  )
}

export default FlowBuilderFormDetailModalComponent
