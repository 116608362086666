import React from 'react'

import './steper.css'

export default function StepBar(props: {amount: number}) {
  return (
    <div className='stepper-wrapper'>
      <div className={`stepper-item ${props.amount >= 0 ? 'completed' : 'active'}`}>
        <div className='step-counter'>1</div>
        <div className='step-name'>Bronze</div>
      </div>
      <div className={`stepper-item ${props.amount > 10000000 ? 'completed' : 'active'}`}>
        <div className='step-counter'>2</div>
        <div className='step-name'>Silver</div>
      </div>
      <div className={`stepper-item ${props.amount > 20000000 ? 'completed' : 'active'}`}>
        <div className='step-counter'>3</div>
        <div className='step-name'>Gold</div>
      </div>
      <div className={`stepper-item ${props.amount > 30000000 ? 'completed' : 'active'}`}>
        <div className='step-counter'>4</div>
        <div className='step-name'>Platinum</div>
      </div>
    </div>
  )
}
