import {AxiosError} from 'axios'
import {ApiProgram} from '../../../../../api/api'
import {GeneralInterface} from '../../../../../interfaces/general.interface'
import {ProgramSingleResultInterface} from '../interfaces/program-single-result.interface'
import {ProgramResultInterface, ProgramData} from '../interfaces/program-result.interface'
import {TopProgramResultInterface} from '../interfaces/top-program-result.interface'

export interface GetAllProgramParam {
  page?: number
  size?: number
  title?: string
  branch_id?: string
}

export interface ProgramDashboardParam {
  start_date?: string
  end_date?: string
}

export const GetAllProgram = async (
  params: GetAllProgramParam
): Promise<ProgramResultInterface> => {
  try {
    let response = await ApiProgram.get('/programs', {
      params: params,
    })
    return response.data
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramResultInterface = a.response?.data
    return response
  }
}

export const ShowProgram = async (id: string): Promise<ProgramSingleResultInterface> => {
  try {
    let response = await ApiProgram.get('/programs/' + id)
    return response.data as ProgramSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramSingleResultInterface = a.response?.data
    return response
  }
}

export const SubmitProgram = async (form: ProgramData): Promise<ProgramSingleResultInterface> => {
  try {
    let response = await ApiProgram.post('/programs', form)
    return response.data as ProgramSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateProgram = async (
  id: string,
  form: ProgramData
): Promise<ProgramSingleResultInterface> => {
  try {
    let response = await ApiProgram.put('/programs/' + id, form)
    return response.data as ProgramSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: ProgramSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteProgram = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.delete('/programs/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const BulkSortingProgram = async (
  ids: string[],
  sorts: number[]
): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.put('/programs-sorting', {
      ids: ids,
      sorts: sorts,
    })
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTotalProgram = async (): Promise<GeneralInterface> => {
  try {
    let response = await ApiProgram.get('/total-active-programs')
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}

export const GetTopProgram = async (
  params: ProgramDashboardParam
): Promise<TopProgramResultInterface> => {
  try {
    let response = await ApiProgram.get('/top-10-programs', {
      params: params,
    })
    return response.data as TopProgramResultInterface
  } catch (e) {
    let a = e as AxiosError
    return a.response?.data as TopProgramResultInterface
  }
}
