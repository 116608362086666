import { AxiosError } from "axios";
import { ApiWallet } from "../../../../../api/api";
import { GeneralInterface } from "../../../../../interfaces/general.interface";
import { WalletPointData, WalletPointResultInterface } from "../interfaces/wallet-point-result.interface";
import { WalletPointSingleResultInterface } from "../interfaces/wallet-point-single-result.interface";

export interface GetAllWalletPointParam {
  page?: number,
  size?: number,
  start_date?: string,
  end_date?: string
}

export const GetAllWalletPoint = async (params: GetAllWalletPointParam): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/wallet-point-users',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const GetAllWalletPointStatus = async (params: GetAllWalletPointParam): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/wallet-point',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const GetTotalUser = async (params: GetAllWalletPointParam): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/total-user',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const GetTotalPointShare = async (params: GetAllWalletPointParam): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/total-point-share',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const GetTotalWalletCash = async (params: GetAllWalletPointParam): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/total-cash',
      {
        params: params
      }
    );
    return response.data;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const ShowWallet = async (id: string): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/total-point/' + id,
    );
    return response.data as WalletPointResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const ShowWalletCash = async (id: string): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get(
      '/wallets-total/' + id,
    );
    return response.data as WalletPointResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: WalletPointResultInterface = a.response?.data;
    return response;
  }
}

export const ShowMyWalletPointHistory = async (
  id: string,
  params: GetAllWalletPointParam
): Promise<WalletPointResultInterface> => {
  try {
    let response = await ApiWallet.get('/point-history/' + id, {params:params})
    return response.data as WalletPointResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: WalletPointResultInterface = a.response?.data
    return response
  }

}

export const SubmitWalletPoint = async (
  form: WalletPointData
): Promise<WalletPointSingleResultInterface> => {
  try {
    let response = await ApiWallet.post('/wallet=point', form)
    return response.data as WalletPointSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: WalletPointSingleResultInterface = a.response?.data
    return response
  }
}

export const UpdateWalletPoint = async (
  id: string,
  form: WalletPointData
): Promise<WalletPointSingleResultInterface> => {
  try {
    let response = await ApiWallet.put('/wallet-point/' + id, form)
    return response.data as WalletPointSingleResultInterface
  } catch (e) {
    let a = e as AxiosError
    let response: WalletPointSingleResultInterface = a.response?.data
    return response
  }
}

export const DeleteWalletPoint = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiWallet.delete('/wallet-point/' + id)
    return response.data as GeneralInterface
  } catch (e) {
    let a = e as AxiosError
    let response: GeneralInterface = a.response?.data
    return response
  }
}