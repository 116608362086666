import {KTCard, KTSVG} from '../../../../../../_metronic/helpers'
import {LoadingComponent} from '../../../crm/properties-management/components/loading.component'
import {useContext, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import {useAuth} from '../../../../auth'

import {useKorcaStore} from '../states/korca-management.state'
import TotalDonationAffiliatorCardComponent from '../../../auth/affiliator-management/components/total-donation-affiliator-card.component'
import DonationSegmentChart from '../../../auth/affiliator-management/components/donation-chart.component'
import {GroupLeaderContextProvider} from '../../group-management/states/group-leader.context'
import {
  GroupContext,
  GroupContextProvider,
  GroupContextState,
} from '../states/group-work-context.state'
import {
  DistrictContextState,
  DistrictContext,
  DistrictContextProvider,
} from '../../../auth/districts-management/states/district.context'
import {generateCurrencyFormat} from '../../../../../helpers/generate-currency-format'
import {
  TotalDonationGroupContext,
  TotalDonationGroupContextProvider,
  TotalDonationGroupContextState,
} from '../../../program/donation/states/total-donation-group.context'

const KorcaMemberListComponent = () => {
  const {getKorcaGroup, korcaGroupItems} = useKorcaStore()
  const {currentUser} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    getKorcaGroup(currentUser?.data?.id)
  }, [])

  return (
    <>
      <KTCard>
        <div className='card-header border-0 pt-6'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Groups</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Korca Member</span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            {/* {groupLoading ? <LoadingComponent /> : <></>} */}
            <table className='table table-row-bordered table-striped table-hover able-row-gray-100 align-middle gs-3 gy-3'>
              <thead>
                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                  <th>Name</th>
                  <th>Wilayah</th>
                  <th>Total Donation</th>
                  <th className='text-end'>Detail</th>
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-semibold'>
                {korcaGroupItems?.group_work?.length === 0 ||
                korcaGroupItems?.group_work == null ? (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No Properties Found
                      </div>
                    </td>
                  </tr>
                ) : (
                  korcaGroupItems?.group_work.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {
                            <GroupContextProvider groupId={item.group_id}>
                              <GroupDetailComponent />
                            </GroupContextProvider>
                          }
                        </td>
                        <td>
                          {
                            <DistrictContextProvider districtId={korcaGroupItems.district_id}>
                              <DistrictDetailComponent />
                            </DistrictContextProvider>
                          }
                        </td>
                        <td>
                          {
                            <TotalDonationGroupContextProvider groupId={item.group_id}>
                              <DonationGroupComponent />
                            </TotalDonationGroupContextProvider>
                          }
                        </td>

                        <td className='text-end'>
                          {korcaGroupItems.district_lead == currentUser?.data?.id ? (
                            <button
                              className='btn btn-success'
                              onClick={(e) => {
                                e.preventDefault()
                                navigate(`/apps/group-management/group-detail/${item.group_id}`)
                              }}
                            >
                              Detail
                            </button>
                          ) : (
                            <button
                              className='btn btn-success'
                              onClick={(e) => {
                                e.preventDefault()
                                navigate(
                                  `/apps/affiliator-management/group-detail/${item.group_id}${item.group_id}`
                                )
                              }}
                              disabled
                            >
                              Detail
                            </button>
                          )}
                        </td>
                      </tr>
                    )
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </KTCard>
    </>
  )
}
const GroupDetailComponent = () => {
  const {group} = useContext<GroupContextState>(GroupContext)
  return <>{group?.group_name ? <>{group?.group_name}</> : '-'}</>
}

const DistrictDetailComponent = () => {
  const {district} = useContext<DistrictContextState>(DistrictContext)
  return <>{district?.name ? <>{district?.name}</> : '-'}</>
}

const KorcaMemberListWrapper = () => {
  return (
    <>
      <KorcaMemberListComponent />
    </>
  )
}
const DonationGroupComponent = () => {
  const {totalDonation} = useContext<TotalDonationGroupContextState>(TotalDonationGroupContext)
  return (
    <>
      {generateCurrencyFormat(totalDonation) ? (
        <>{generateCurrencyFormat(totalDonation)}</>
      ) : (
        generateCurrencyFormat(0)
      )}
    </>
  )
}

export {KorcaMemberListWrapper}
