import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {MenuItem} from '../../header/header-menus/MenuItem'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainAdminPlatform = () => {
  const {currentUser} = useAuth()
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/apps/donation'
        title='Donation'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com017.svg'
      >
        <SidebarMenuItem
          to='/apps/crm/customer-management/customer'
          title='Donatur Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/donation/donation-management/donation'
          title='Donation Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-management/programs'
          title='Campaign Management'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/apps/program/news-management/news'
          title='News Management'
          hasBullet={true}
        />

        <SidebarMenuItem
          to='/apps/program/banner-management/banner'
          title='Banner Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/apps/ffiliator-management'
        title='Affiliate'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com019.svg'
      >
        <SidebarMenuItem
          to='/apps/affiliator-management/affiliator'
          title='Affiliator Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/korca-management/korca'
          title='Management Korca'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/group-management/work-group'
          title='Management Group'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/service-unit-management/service-unit'
          title='Management Unit'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/level-affiliator-management/'
          title='Level Affiliator'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section  text-white text-uppercase fs-8 ls-1'>Master Data</span>
        </div>
      </div>

      <SidebarMenuItemWithSub
        to='/master/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/master/program/donation-target-management/donation-target'
          title='Donation Target'
          hasBullet={true}
        />
        {/* <SidebarMenuItem
          to='/apps/program/channel-management/channel'
          title='Channel'
          hasBullet={true}
        /> */}
        <SidebarMenuItem
          to='/master/program/payment-management/payment'
          title='Payment'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/program/program-category-management/program-category'
          title='Program Category'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/master/program/donation-type-management/donation-type'
          title='Donation Type'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-white text-uppercase fs-8 ls-1'>{`SETTING`}</span>
        </div>
      </div>

      <SidebarMenuItem
        to='/apps/platform-donation-settings-management/platform-donation-settings'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Platform Donasi'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/company-management/company'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Company'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/crm/channel-management/channel'
        title='Channel Management'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com016.svg'
      />
    </>
  )
}

export default SidebarMenuMainAdminPlatform
