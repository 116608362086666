import { AxiosError } from "axios";
import { ApiCrm } from "../../../../../api/api";
import { GeneralInterface } from "../../../../../interfaces/general.interface";
import { FlowResultInterface } from "../interfaces/flow-result.interface";
import { FlowSingleResultInterface } from "../interfaces/flow-single-result.interface";

export interface GetAllFlowParam {
  page?: number,
  size?: number,
  company_id?: string,
  flow_type?: string,
}

export interface GetTransactionFlowParam {
  page?: number,
  size?: number,
  company_id?: string,
  flow_type?: string,
}

export const GetAllFlow = async (params: GetAllFlowParam): Promise<FlowResultInterface> => {
  try {
    let response = await ApiCrm.get(
      '/flows',
      {
        params: params
      }
    );
    return response.data as FlowResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FlowResultInterface = a.response?.data;
    return response;
  }
}

export const GetTransactionFlow = async (params: GetTransactionFlowParam): Promise<FlowResultInterface> => {
  try {
    let response = await ApiCrm.get(
      '/flows/transactional',
      {
        params: params
      }
    );
    return response.data as FlowResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FlowResultInterface = a.response?.data;
    return response;
  }
}

export const ShowFlow = async (id: string): Promise<any> => {
  try {
    let response = await ApiCrm.get(
      '/flows/' + id,
    );
    return response.data as FlowSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FlowSingleResultInterface = a.response?.data;
    return response;
  }
}

export const ShowFlowByReference = async (reference: string): Promise<FlowResultInterface> => {
  try {
    let response = await ApiCrm.get(
      '/flows-by-reference',
      {
        params: {
          reference_table: reference
        }
      }
    );
    return response.data as FlowResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FlowResultInterface = a.response?.data;
    return response;
  }
}

export const SubmitFlow = async (form: any): Promise<FlowSingleResultInterface> => {
  try {
    let response = await ApiCrm.post(
      '/flows',
      form
    );
    return response.data as FlowSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FlowSingleResultInterface = a.response?.data;
    return response;
  }
}

export const UpdateFlow = async (id: string, form: any): Promise<FlowSingleResultInterface> => {
  try {
    let response = await ApiCrm.put(
      '/flows/' + id,
      form
    );
    return response.data as FlowSingleResultInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: FlowSingleResultInterface = a.response?.data;
    return response; 
  }
}

export const DeleteFlow = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.delete(
      '/flows/' + id
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}

export const BulkSortingFlow = async (ids: string[], sorts: number[]): Promise<GeneralInterface> => {
  try {
    let response = await ApiCrm.put(
      '/flows-sorting',
      {
        ids: ids,
        sorts: sorts
      }
    );
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
}