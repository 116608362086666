import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMainMitraLembaga = () => {
  return (
    <>
      <SidebarMenuItem
        to='/apps/mitra-lembaga-dashboard/dashboard'
        title='Dashboard'
        hasBullet={true}
      />
       <SidebarMenuItemWithSub
        to='/apps/donation'
        title='Donation'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com017.svg'
      >
        <SidebarMenuItem
          to='/apps/crm/donatur-management/donatur'
          title='Donatur Management'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/apps/program/donation-management/donation'
          title='Donation Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/apps/program'
        title='Program'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com018.svg'
      >
        <SidebarMenuItem
          to='/apps/program/program-mitra/programs'
          title='Campaign Management'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  )
}

export default SidebarMenuMainMitraLembaga
