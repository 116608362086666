import {SelectData} from '../../../../../interfaces/form-action-interfaces'
import {CityData, CityResultInterface} from '../interfaces/city-result.interface'
import {GetAllCity, GetAllCityParam} from '../providers/city.provider'
import create from 'zustand'

interface CityState {
  cityLoading: boolean
  cityItems: CityData[]
  citySelectData: SelectData[]
  cityModel?: CityResultInterface
  getCities: (params: GetAllCityParam) => void
  onDelete?: (id: string, params: GetAllCityParam) => void
  reset?: () => void
}

export const useCityStore = create<CityState>((set, get) => ({
  reset: () => {
    set({
      citySelectData: [],
      cityModel: undefined,
      cityItems: [],
    })
  },
  cityLoading: false,
  cityItems: [],
  citySelectData: [],
  cityModel: undefined,
  getCities: async (params: GetAllCityParam) => {
    set({cityLoading: true})
    const response = await GetAllCity(params)
    if (response.status) {
      if (response.data?.items?.length > 0) {
        let transformDatas: SelectData[] = []
        for (const item of response.data.items) {
          transformDatas.push({
            label: item.name,
            value: item.id,
          })
        }
        set({
          citySelectData: transformDatas,
          cityItems: response.data.items,
        })
      } else {
        set({
          citySelectData: [],
          cityItems: [],
        })
      }
    } else {
      set({
        citySelectData: [],
        cityItems: [],
      })
    }
    set({cityLoading: false})
  },
  onDelete: (id: string, params: GetAllCityParam) => {},
}))
